import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import useLocalStorage from '~/hooks/useLocalStorage'
import { Heart } from './Heart'
import { useMedia } from '~/hooks/useMedia'

type Props = {
  className?: string
  sku: string
  isProductCard?: boolean
  trackingHandler?: () => void
}

export const GuestAddToWishlist = ({ className, sku, isProductCard, trackingHandler }: Props) => {
  const [isOverHeart, setIsOverHeart] = useState(false)
  const { t } = useTranslation()
  const isDesktop = useMedia('lg')
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  const [guestWishlistSku, setGuestWishlistSku] = useLocalStorage<string[]>('guestWishlistSku', [])
  const [isInWishlist, setIsInWishlist] = useState(guestWishlistSku.includes(sku))
  const handleClick = () => {
    trackingHandler && trackingHandler()
    setIsInWishlist(!isInWishlist)

    if (guestWishlistSku.includes(sku)) {
      setGuestWishlistSku([...guestWishlistSku.filter((key) => key !== sku)])
    } else {
      setGuestWishlistSku([sku, ...guestWishlistSku])
    }
  }
  const toggleHover = () => {
    if (!isDesktop) return
    setIsOverHeart(!isOverHeart)
  }
  if (!hasMounted) {
    return null
  }
  return (
    <button
      title={isInWishlist ? t('Remove from wishlist') : t('Add to wishlist')}
      className={clsx(
        'grid h-8.5 w-8.5 place-content-center overflow-hidden rounded-full bg-grey-light transition',
        className,
      )}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={handleClick}
    >
      <Heart active={isInWishlist} isHovered={isOverHeart} isProductCard={isProductCard} />
    </button>
  )
}
