import { ConfigurableProduct, ProductInterface, Maybe } from '@magentoTypes'

export const getRelatedSkus = (product: ConfigurableProduct) => {
  if (!product) return []

  const relatedProducts = (product.related_products as ConfigurableProduct[])
    .map((related) => related.variants?.map((variant) => variant?.product?.sku))
    .flat()

  let uniqueChars = [] as Maybe<string>[]
  relatedProducts?.forEach((element) => {
    //@ts-ignore
    if (!uniqueChars.includes(element)) {
      //@ts-ignore
      uniqueChars.push(element)
    }
  })
  const variants = product.variants?.map((variant) => variant?.product?.sku) || []

  return [...new Set([...variants, ...uniqueChars])]
}
