import { ConfigurableProduct } from '@magentoTypes'

export const useRebates = () => {
  const calculateDiscount = (regularPrice?: number, specialPrice?: number) => {
    if (regularPrice && specialPrice && regularPrice > specialPrice) {
      const discount = regularPrice - specialPrice
      const percent = (discount / regularPrice) * 100

      return Math.round(percent)
    }

    return 0
  }

  const calculateRebates = (product: ConfigurableProduct) => {
    return calculateDiscount(
      product?.price_range?.minimum_price?.regular_price?.value as number,
      product?.price_range?.minimum_price?.final_price?.value as number,
    )

    /*const discounts = [] as number[]

    product?.variants?.forEach((p) => {
      discounts.push(
        calculateDiscount(
          p?.product?.price_range?.minimum_price?.regular_price?.value as number,
          p?.product?.price_range?.minimum_price?.final_price?.value as number,
        ),
      )
    })

    return discounts.sort((a: number, b: number) => (a < b ? 1 : -1))?.[0] ?? 0*/
  }

  return {
    calculateDiscount,
    calculateRebates,
  }
}
