import { useSession } from '~/hooks/useSession'
import { UserAddToWishlist } from './components/UserAddToWishlist'
import { GuestAddToWishlist } from './components/GuestAddToWishlist'

type Props = {
  className?: string
  sku: string
  isProductCard?: boolean
  trackingHandler?: () => void
}

export const AddToWishlist = ({ className, sku, isProductCard, trackingHandler }: Props) => {
  const [session] = useSession()
  return (
    <>
      {session ? (
        <UserAddToWishlist
          className={className}
          trackingHandler={trackingHandler}
          sku={sku}
          isProductCard={isProductCard}
        />
      ) : (
        <GuestAddToWishlist
          className={className}
          trackingHandler={trackingHandler}
          sku={sku}
          isProductCard={isProductCard}
        />
      )}
    </>
  )
}
