import { useSpring, a } from '@react-spring/web'
import clsx from 'clsx'

type Props = {
  active: boolean
  isHovered: boolean
  isProductCard?: boolean
}
export const Heart = ({ active, isHovered, isProductCard }: Props) => {
  const spring = useSpring({
    fill:
      active && !isHovered
        ? '#E12A57'
        : active && isHovered
        ? '#909090'
        : !active && isHovered
        ? '#E12A57'
        : 'transparent',
  })

  return (
    <svg
      fill="none"
      className={clsx(isProductCard ? 'h-5 w-5' : 'h-6 w-6')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <a.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.05 4.65c2.433 0 4.3 1.838 4.3 4.146 0 1.427-.657 2.777-1.956 4.316-1.306 1.547-3.187 3.201-5.518 5.249h-.001l-.877.774-.874-.765c-2.33-2.052-4.212-3.709-5.518-5.257-1.3-1.54-1.956-2.89-1.956-4.317 0-2.308 1.867-4.146 4.3-4.146 1.377 0 2.703.624 3.562 1.601l.488.556.488-.556A4.812 4.812 0 0 1 16.05 4.65Zm5.6 4.146c0-3.064-2.489-5.446-5.6-5.446-1.513 0-2.97.586-4.05 1.551A6.132 6.132 0 0 0 7.95 3.35c-3.112 0-5.6 2.382-5.6 5.446 0 1.868.873 3.509 2.262 5.156 1.37 1.622 3.31 3.33 5.585 5.333l.068.06.002.002 1.735 1.518 1.732-1.528.046-.04c2.284-2.007 4.234-3.72 5.607-5.346 1.39-1.646 2.263-3.287 2.263-5.155Z"
        fill={isProductCard ? '#000000' : '#909090'}
      />
      <a.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.65 8.796c0-3.064-2.489-5.446-5.6-5.446-1.513 0-2.97.586-4.05 1.551A6.132 6.132 0 0 0 7.95 3.35c-3.112 0-5.6 2.382-5.6 5.446 0 1.868.873 3.509 2.262 5.156 1.37 1.622 3.31 3.33 5.585 5.333l.068.06.002.002 1.735 1.518 1.732-1.528.046-.04c2.284-2.007 4.234-3.72 5.607-5.346 1.39-1.646 2.263-3.287 2.263-5.155Z"
        {...spring}
      />
    </svg>
  )
}
