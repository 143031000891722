import { ConfigurableProduct } from '@magentoTypes'
import clsx from 'clsx'
import { getRelatedSkus } from '~/modules/PDP/lib/getRelatedSkus'
import { RATING_CLASS_NAME } from '../lib/constants'
import { Icon } from '~/elements/Icon/Icon'

type Props = {
  product: ConfigurableProduct
  withText?: boolean
  withSpacing?: boolean
}

const ProductRating: React.FC<Props> = ({ product, withSpacing, withText }) => {
  return (
    <div
      key={`product_rating_${product.sku}`}
      className={clsx(RATING_CLASS_NAME, 'h-[24px] min-w-0', {
        '[&_span]:hidden': !withText,
        'mt-3': withSpacing,
      })}
      data-sku={getRelatedSkus(product).join(';')}
    >
      <div className="flex animate-pulse">
        {[0, 1, 2, 3, 4].map((i) => (
          <Icon
            key={`loding_star_${i}`}
            name="single-star"
            style={{ color: '#CACACA', width: '18px', height: '18px' }}
          />
        ))}
      </div>
    </div>
  )
}

export { ProductRating }
