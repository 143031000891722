import { useElementSize } from '@charlietango/hooks'
import {
  ConfigurableCartItem,
  ConfigurableProduct,
  useAddToCartMutation,
  useCartPageQuery,
  useFetchCartHeaderQuery,
} from '@magentoTypes'
import { Image } from '@next/image'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'
import { AddToWishlist } from '~/elements/AddToWishlist/AddToWishlist'
import { Link } from '~/elements/Link/Link'
import { useFormatPrice } from '~/hooks/useFormatPrice'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { useMedia } from '~/hooks/useMedia'
import { ColorThumbs } from './ColorThumbs'
import { useRouter } from 'next/router'
import { useBrandType } from '~/hooks/useBrandType'
import clsx from 'clsx'
import { useQueryClient } from '@tanstack/react-query'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { CART_ID, DEFAULT_LOCALE } from '~/config/constants'
import { useRebates } from '~/hooks/useRebates'
import { createMagentoClient } from '~/graphql/magentoClient'
import { toast } from 'react-toastify'
import { useProductAttributes } from '~/hooks/useProductAttributes'
import ProductCardIcon from './Icons/ProductCardIcon'
import { ProductRating } from '~/modules/ClientReviews/components/ProductRating'

type Props = {
  product: ConfigurableProduct
  productPrice?: ConfigurableProduct
  hasBorderRight: boolean
  hasBorderTop: boolean
  hasPriority?: boolean
  className?: string
  colCount?: number
  isSlider?: boolean
  showSampleCta?: boolean
  deferHydration?: boolean
  cartId?: string
  getImageHeight?: (val: number) => void
  onSampleAddedToCart?: () => void
}

const samplesValueIndex = [
  11932, // 15x15cm - Live/Staging
  10771, // 10x10cm - Live/Staging
]

export const ProductCard = ({
  product,
  productPrice,
  hasBorderRight,
  hasBorderTop,
  hasPriority,
  className,
  colCount,
  getImageHeight,
  isSlider,
  showSampleCta,
  deferHydration,
  cartId,
  onSampleAddedToCart,
}: Props) => {
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const queryClient = useQueryClient()
  const magentoClient = createMagentoClient({ usePost: true, queryClient })
  const { dataLayer } = useGTM()
  const [isHydrated, setIsHydrated] = useState(!!!deferHydration)
  const [hoveredIndex, setHoveredIndex] = useState(0)
  const [_, { height: imgHeight }] = useElementSize()
  const isDesktop = useMedia('lg')
  const hasBestseller = (product?.umsatz || -1) >= 0 && (product?.umsatz || -1) <= 35
  const hasAR = product?.benuta_ar
  const hasSustainability = ['38552', '38429'].includes(product?.benuta_test_seal as string)
  const isHandmade = ['19633', '37116', '19636', '19642', '19639', '37602'].includes(
    product?.benuta_production_type as string,
  )
  const isCotton = [
    18892, 18949, 18973, 38417, 38625, 38001, 37542, 36949, 37530, 36745, 37518, 19123, 19126,
    36739, 36754, 19162, 19171, 19609, 36748, 19219, 19285, 19606, 19366, 19369, 19612, 19567,
    19438, 19450, 19453, 19456, 19510, 37551, 37587, 38384, 38474, 38586, 38648, 19420, 19447,
    19534, 19537,
  ].includes(product?.benuta_pile_material as number)
  const isWolle = [
    18928, 18883, 37548, 18976, 18961, 36742, 37578, 37563, 38023, 37593, 37599, 37590, 19600,
    19066, 19075, 19108, 19111, 19783, 19780, 19159, 19582, 19195, 19789, 19204, 19267, 19273,
    19078, 19276, 19282, 19579, 19096, 19099, 19336, 19342, 19129, 19378, 19387, 19390, 19396,
    19399, 19981, 19174, 19411, 19177, 19189, 19432, 19435, 19489, 19495, 19498, 19507, 19231,
    19234, 19519, 19543, 37566, 19255, 19258, 19270, 37581, 19279, 38540, 19315, 19333, 38592,
    19321, 38662, 19339, 38668, 38675, 38689, 19360, 38740, 38795, 19372, 38792, 38807, 19375,
    38861, 19393, 19444, 19459, 19465, 19480, 19483, 19492, 19522, 19546,
  ].includes(product?.benuta_pile_material as number)
  const isWashable = [20297, 20300, 20303, 20306, 20309].includes(
    product?.benuta_waschbar as number,
  )

  const { t } = useTranslation()
  const { formatPrice } = useFormatPrice()
  const { calculateRebates } = useRebates()
  const { findLabel } = useProductAttributes()

  const addToCartMutation = useAddToCartMutation(magentoClient, {
    onError: async () => {
      await queryClient.invalidateQueries([CART_ID])
      await queryClient.invalidateQueries(['session'])
    },
  })

  const getDiscountAmount = () => {
    return calculateRebates(productPrice ?? product)
  }

  const hasSalePrice = () => {
    if (productPrice) {
      const regularPrice = productPrice?.price_range?.minimum_price?.regular_price?.value
      const finalPrice = productPrice?.price_range?.minimum_price?.final_price?.value
      return finalPrice !== regularPrice
    } else {
      const regularPrice = product?.price_range?.minimum_price?.regular_price?.value
      const finalPrice = product?.price_range?.minimum_price?.final_price?.value
      return finalPrice !== regularPrice
    }
  }
  const isNewProduct = (created_at: string): boolean => {
    const createdAtDate = new Date(created_at)
    const currentDate = new Date()
    const timeDifference = currentDate.getTime() - createdAtDate.getTime()
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
    return daysDifference <= 180
  }
  const backgroundImage = useMemo(() => {
    if (!product) return null
    const images = product?.media_gallery
      ?.filter((item) => !item?.disabled)
      .find((item) => item?.position === 1)
    return images
  }, [product])

  const hoverIndex = (value: number) => {
    if (isDesktop) {
      setHoveredIndex(value)
    }
  }

  const mainImages = useMemo(() => {
    const list = [product?.image?.url]
    product?.related_products?.slice(0, 4)?.map((related) => {
      list.push(related?.image?.url)
    })
    return list
  }, [product])

  const calcPadding = (cols: number | undefined) => {
    return cols === 6 ? 'pt-4' : 'pt-5'
  }

  const calcRatingMargin = (cols: number | undefined) => {
    if (cols === 1) return 'mb-14'
    if (cols === 3) return 'mb-10'

    return 'mb-1'
  }

  const calc = (cols: number | undefined) => {
    if (cols === 6) return { height: 'calc(100% + 16px)', marginTop: '-16px' }
    else return { height: 'calc(100% + 20px)', marginTop: '-20px' }
  }

  useEffect(() => {
    getImageHeight?.(imgHeight)
  }, [imgHeight, getImageHeight])

  useEffect(() => {
    if (router.isReady && !isHydrated && productPrice) setIsHydrated(true)
  }, [router.isReady, productPrice])

  const onSendDataLayer = (eventName: string) => {
    const price = productPrice
      ? productPrice.price_range?.minimum_price?.final_price?.value !==
        productPrice.price_range?.minimum_price?.regular_price?.value
        ? productPrice.price_range?.minimum_price?.final_price
        : productPrice.price_range?.minimum_price?.regular_price
      : product.price_range?.minimum_price?.final_price?.value !==
        product.price_range?.minimum_price?.regular_price?.value
      ? product.price_range?.minimum_price?.final_price
      : product.price_range?.minimum_price?.regular_price
    dataLayer(DataLayerEnum.EVENT, eventName, {
      dataLayer: {
        ecommerce: {
          currency: price?.currency || 'EUR',
          items: [
            {
              item_id: product?.sku,
              item_name: product.name,
              price: price?.value,
            },
          ],
        },
      },
    })
  }

  const brandType = useBrandType({ manufacturer: product?.manufacturer as number })

  const isSoldOut = productPrice
    ? productPrice?.stock_status === 'OUT_OF_STOCK'
    : product?.stock_status === 'OUT_OF_STOCK'

  const productUrl = `${
    Array.isArray(product.url_rewrites) && product.url_rewrites[0]?.url
      ? product.url_rewrites[0]?.url
      : `${product?.url_key as string}.html`
  }#${product?.uid}`

  const { data: cartData } = useCartPageQuery(
    magentoClient,
    { cartId: cartId || '' },
    { enabled: !!cartId && cartId !== '' && showSampleCta },
  )
  const [isSampleAdding, setIsSampleAdding] = useState(false)
  const getSampleUid = (): string => {
    const attributes = product?.configurable_options?.[0]
    let sampleUid = ''

    if (attributes?.attribute_code === 'size') {
      attributes?.values?.forEach((attr) => {
        if (samplesValueIndex.includes(attr?.value_index as number)) sampleUid = attr?.uid as string
      })
    }

    return sampleUid
  }
  const getSampleboxSlotsLeft = (): number => {
    let samplesQty = 0

    ;(cartData?.cart?.items as ConfigurableCartItem[])?.forEach((item) => {
      if (samplesValueIndex.includes(item?.configurable_options?.[0]?.value_id as number))
        samplesQty += item.quantity
    })

    return samplesQty
  }
  const addSampleboxToCart = async () => {
    // Update the flag
    setIsSampleAdding(true)
    // Check if any slots left on the samplebox
    if (getSampleboxSlotsLeft() >= 5) {
      // Inform user from the error
      toast.error(t('Your sample box is full'))
      // Tell parent component to open samplebox flyout
      onSampleAddedToCart?.()
      // Update the flag
      setIsSampleAdding(false)
    } else {
      // Check if sample already added to the cart
      const isProductAlreadyAdded =
        (cartData?.cart?.items as ConfigurableCartItem[])?.findIndex(
          (item) =>
            product?.sku === item?.product?.sku &&
            samplesValueIndex.includes(item?.configurable_options?.[0]?.value_id as number),
        ) !== -1
      if (isProductAlreadyAdded) {
        // Inform user from the error
        toast.error(t('This sample is already in your sample box'))
        // Tell parent component to open samplebox flyout
        onSampleAddedToCart?.()
        // Update the flag
        setIsSampleAdding(false)
      } else {
        // Add the sample to the cart
        addToCartMutation
          .mutateAsync({
            cartId: cartId as string,
            cartItems: [
              {
                quantity: 1,
                selected_options: [getSampleUid()],
                sku: product?.sku as string,
              },
            ],
          })
          .then(async (data) => {
            if (data?.addProductsToCart?.user_errors?.length || 0 > 0) {
              // Inform user from the error
              toast.error(data?.addProductsToCart?.user_errors?.[0]?.message)
            } else {
              // Flush query caches
              await queryClient.refetchQueries(useCartPageQuery.getKey({ cartId: cartId || '' }))
              await queryClient.invalidateQueries(
                useFetchCartHeaderQuery.getKey({ cartId: cartId || '' }),
              )
              // Inform user from success operation
              toast.success(t('The sample has been added to your sample box'))
              // Tell parent component to open samplebox flyout
              onSampleAddedToCart?.()
            }
            // Update the flag
            setIsSampleAdding(false)
          })
          .catch(async (error) => {
            // Flush query caches
            await queryClient.refetchQueries(useCartPageQuery.getKey({ cartId: cartId || '' }))
            await queryClient.invalidateQueries(
              useFetchCartHeaderQuery.getKey({ cartId: cartId || '' }),
            )
            // Inform user from the error
            toast.error(error?.message)
            // Update the flag
            setIsSampleAdding(false)
          })
      }
    }
  }

  const generateAltTags = (product: ConfigurableProduct) => {
    const isRugProduct = product?.attribute_set_id === 115
    return isRugProduct
      ? t(
          'Rug {{Placeholder_Collection_name}} made of {{Placeholder_Material}} in {{Placeholder_Colour}} by {{Placeholder_Subbrand}}',
          {
            Placeholder_Collection_name: findLabel({
              attributeCode: 'benuta_collection',
              attributeValue: product?.benuta_collection as string,
            }),
            Placeholder_Colour: findLabel({
              attributeCode: 'benuta_color_filter',
              attributeValue: product?.benuta_color_filter as number,
            }),
            Placeholder_Material: findLabel({
              attributeCode: 'benuta_pile_material',
              attributeValue: product?.benuta_pile_material as number,
            }),
            Placeholder_Subbrand: findLabel({
              attributeCode: 'manufacturer',
              attributeValue: product?.manufacturer as number,
            }),
          },
        )
      : t(
          '{{Placeholder_Product_Name}} in {{Placeholder_First_Element_of_Design}} design by {{Placeholder_Subbrand}}',
          {
            Placeholder_Product_Name: product?.name || '',
            Placeholder_First_Element_of_Design: findLabel({
              attributeCode: 'benuta_motive_design',
              attributeValue: product?.benuta_motive_design as string,
            }).split(',')?.[0],
            Placeholder_Subbrand: findLabel({
              attributeCode: 'manufacturer',
              attributeValue: product?.manufacturer as number,
            }),
          },
        )
  }

  return (
    <figure
      id={product?.url_key as string}
      className={clsx(
        'relative h-full border-b border-grey-border border-opacity-50',
        hasBorderRight ? 'border-r' : '',
        hasBorderTop ? 'border-t' : '',
        calcPadding(colCount),
        className,
      )}
    >
      {brandType && (
        <div className="absolute right-0 z-20 mt-[102%] p-2">
          <Image
            src={brandType.svg}
            width={50}
            height={22}
            placeholder="empty"
            alt={brandType.name}
          />
        </div>
      )}
      <div className="absolute left-0 z-20 flex w-full flex-row-reverse justify-between p-2 lg:top-5">
        <div className="">
          <AddToWishlist
            isProductCard={true}
            trackingHandler={() => onSendDataLayer('add_to_wishlist')}
            sku={product?.sku as string}
          />
          {hasAR && (
            <div className="mt-1 flex h-8.5 w-8.5 items-center justify-center overflow-hidden rounded-full bg-grey-light">
              <ProductCardIcon name="ar" className="h-5 w-5 " />
            </div>
          )}
        </div>
        <div className="">
          {/*isHydrated && getDiscountAmount() > 0 && (
            <div className="w-fit bg-[#F3D750] px-2 text-p-small font-bold text-primary-black">
              {t('up to -{{discount}}%', {
                discount: getDiscountAmount(),
              })}
            </div>
          )*/}
          {hasBestseller && (
            <div className="mt-1 w-fit bg-[#96A9AB] px-2 text-p-small text-primary-white">
              {t('bestseller')}
            </div>
          )}
          {isNewProduct(product?.created_at as string) && (
            <div className="mt-1 w-fit bg-[#000] px-2 text-p-small text-primary-white">
              {t('new')}
            </div>
          )}
        </div>
      </div>
      <div className={clsx('group relative aspect-[4/5] lg:z-auto')}>
        {backgroundImage?.url && isDesktop ? (
          <div
            className={clsx(
              'pointer-events-none absolute z-10 -mt-14 hidden w-full opacity-0 transition-opacity duration-500 ease-in-out group-hover:pointer-events-auto group-hover:opacity-100 lg:block',
            )}
            style={{ height: calc(colCount).height, marginTop: calc(colCount).marginTop }}
          >
            <div className="absolute right-2 top-2 z-10 grid grid-flow-col gap-4 lg:top-4 lg:right-4"></div>
            <button
              className="h-full w-full max-w-full"
              onClick={() => onSendDataLayer('select_item')}
            >
              <Link
                href={productUrl}
                title={product?.name as string}
                className="relative block h-full w-full"
              >
                <Image
                  src={backgroundImage?.url}
                  alt={generateAltTags(product)}
                  fill
                  style={{ objectFit: 'cover' }}
                  sizes={
                    isSlider ? '(max-width: 1024px) 75vw, 16vw' : '(max-width: 1024px) 50vw, 25vw'
                  }
                />
              </Link>
            </button>
          </div>
        ) : null}
        <button className="h-full w-full max-w-full" onClick={() => onSendDataLayer('select_item')}>
          <Link
            href={productUrl}
            title={product?.name as string}
            className="relative block h-full w-full"
          >
            <Image
              //TODO: Remove fallback image after fallback is set in magento
              src={
                mainImages?.[hoveredIndex]?.includes('placeholder')
                  ? '/images/fallback_product.avif'
                  : (mainImages?.[hoveredIndex] as string)
              }
              alt={generateAltTags(product)}
              fill
              style={{ objectFit: 'contain' }}
              sizes={isSlider ? '(max-width: 1024px) 75vw, 16vw' : '(max-width: 1024px) 50vw, 25vw'}
              priority={!!hasPriority}
              className="aspect-[4/5]"
            />
          </Link>
        </button>
      </div>
      <div className="flex w-full justify-center py-2">
        <figcaption className="flex w-[188px] flex-col items-center">
          <div className="mb-[2px] grid min-h-[20px] grid-flow-col gap-2 lg:mb-2 lg:gap-3">
            {isWolle && (
              <div className="flex items-center	justify-center bg-[#EFEEE5] px-2 text-[#141414]">
                <ProductCardIcon name="wolle" />
                <span className="ml-1 text-[9px]">{t('wool')}</span>
              </div>
            )}
            {isCotton && !isWolle && (
              <div className="flex items-center	justify-center bg-[#EFEEE5] px-2 text-[#141414]">
                <ProductCardIcon name="cotton" />
                <span className="ml-1 text-[9px]">{t('cotton')}</span>
              </div>
            )}
            {hasSustainability && (
              <div className="flex items-center	justify-center bg-[#BCD0BC] px-2 text-[#141414]">
                <ProductCardIcon name="sustainability" />
                <span className="ml-1 text-[9px]">{t('certified')}</span>
              </div>
            )}
            {isHandmade && !hasSustainability && (
              <div className="flex items-center	justify-center bg-[#E3E1E4] px-2 text-[#141414]">
                <ProductCardIcon name="handmade" />
                <span className="ml-1 text-[9px]">{t('handmade')}</span>
              </div>
            )}
            {isWashable && !isHandmade && !hasSustainability && (
              <div className="flex items-center	justify-center bg-[#BBDEDD] px-2 text-[#141414]">
                <ProductCardIcon name="washable" />
                <span className="ml-1 text-[9px]">{t('washable')}</span>
              </div>
            )}
          </div>
          <button
            className="mt-2 max-w-full lg:mt-0"
            onClick={() => onSendDataLayer('select_item')}
          >
            <Link
              href={`${product?.url_key as string}.html#${product?.uid}`}
              title={product?.name as string}
              className="block max-w-full"
            >
              <p className="mb-[2px] max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap px-[2px] font-medium md:px-0">
                {product?.name}
              </p>
            </Link>
          </button>
          {!showSampleCta && (
            <>
              {isHydrated && !isSoldOut && (
                <div className="text-center text-p">
                  {hasSalePrice() ? (
                    ['benuta_cz', 'benuta_pl', 'benuta_dk'].includes(
                      process.env.NEXT_PUBLIC_STORE_CODE ?? 'benuta_de',
                    ) ? (
                      <>
                        <div className="text-primary-black line-through">
                          {formatPrice(
                            productPrice
                              ? productPrice?.price_range?.minimum_price?.regular_price
                              : product?.price_range?.minimum_price?.regular_price,
                          )}
                        </div>
                        <div className="w-fit bg-[#F3D750] px-1 font-bold text-primary-black">
                          {t('from')}{' '}
                          {formatPrice(
                            productPrice
                              ? productPrice?.price_range?.minimum_price?.final_price
                              : product?.price_range?.minimum_price?.final_price,
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="text-primary-black line-through">
                          {formatPrice(
                            productPrice
                              ? productPrice?.price_range?.minimum_price?.regular_price
                              : product?.price_range?.minimum_price?.regular_price,
                          )}
                        </span>
                        <span className="ml-[6px] bg-[#F3D750] px-1 font-bold text-primary-black">
                          {t('from')}{' '}
                          {formatPrice(
                            productPrice
                              ? productPrice?.price_range?.minimum_price?.final_price
                              : product?.price_range?.minimum_price?.final_price,
                          )}
                        </span>
                      </>
                    )
                  ) : (
                    <span className="text-primary-black">
                      {t('from')}{' '}
                      {formatPrice(
                        productPrice
                          ? productPrice?.price_range?.minimum_price?.regular_price
                          : product?.price_range?.minimum_price?.regular_price,
                      )}
                    </span>
                  )}
                </div>
              )}
              {!showSampleCta && <ProductRating product={product} withSpacing />}
              <div className={clsx(calcRatingMargin(colCount ?? 0))}>
                <ColorThumbs product={product} hoverIndex={hoverIndex} />
              </div>
            </>
          )}
          {showSampleCta && cartId && (
            <div className="mt-4 flex justify-center py-2 text-center">
              {getSampleUid() !== '' && (
                <button
                  className="items-top flex w-full underline decoration-2 underline-offset-8"
                  disabled={isSampleAdding}
                  onClick={addSampleboxToCart}
                >
                  <ProductCardIcon name="sample-2" className="h-6 w-6" />
                  <span className="ml-1.5 text-p-small">
                    {t(isSampleAdding ? 'Will be added' : 'Add sample to box')}
                  </span>
                </button>
              )}
            </div>
          )}
        </figcaption>
      </div>
    </figure>
  )
}
