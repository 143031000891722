import { ConfigurableProduct } from '@magentoTypes'
import { Image } from '@next/image'
import clsx from 'clsx'
import { Link } from 'elements/Link/Link'
import { useMemo } from 'react'
import { useMedia } from 'hooks/useMedia'

type Props = {
  className?: string
  product: ConfigurableProduct
  hoverIndex: (arg: number) => void
}

export const ColorThumbs = ({ className, product, hoverIndex }: Props) => {
  const isDesktop = useMedia('md')
  const relatedLength =
    product.related_products?.filter((elem) => elem?.benuta_form_new === product?.benuta_form_new)
      .length || 0
  const combinedColors = useMemo(() => {
    const relatedProducts = product?.related_products?.filter(
      (elem) => elem?.benuta_form_new === product?.benuta_form_new,
    )
    const colors = []
    if (relatedProducts?.length) {
      colors.push({ url_key: product?.url_key, thumbnail: product?.small_image })
      relatedProducts?.forEach((related) => {
        colors.push(related)
      })
    }

    if (isDesktop) {
      return colors.length > 5 ? colors.slice(0, 4) : colors
    }
    return colors.length > 3 ? colors.slice(0, 2) : colors
  }, [isDesktop, product.related_products, product?.small_image, product?.url_key])

  if (combinedColors.length === 0) return null

  const extraColorsLength = () => {
    const correctedLength = relatedLength + 1
    if (isDesktop) {
      return correctedLength > 4 ? correctedLength - 3 : 0
    }
    return correctedLength > 2 ? correctedLength - 1 : 0
  }

  return (
    <ul
      className={clsx('mt-4 grid grid-flow-col gap-3 lg:mt-6', className)}
      onMouseLeave={() => hoverIndex(0)}
    >
      {combinedColors?.map(({ url_key, thumbnail }, index) => (
        <li
          key={url_key}
          className={clsx(
            'relative rounded-full',
            index === 0
              ? 'h-5 w-5 rounded-full border border-primary-black p-[3px]'
              : 'duration-400 max-h-[20px] max-w-[20px] rounded-full border-0 border-grey-border transition-all hover:border hover:p-[3px]',
          )}
          onMouseEnter={() => hoverIndex(index)}
        >
          <Link
            href={`${url_key as string}.html#${product?.uid}`}
            title={url_key as string}
            className={clsx(
              'relative block h-5 max-h-full w-5 max-w-full overflow-hidden rounded-full',
            )}
          >
            <Image
              src={thumbnail?.url as string}
              alt={thumbnail?.label as string}
              className="h-5 w-5"
              width={100}
              height={100}
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
                transform: 'scale(3)',
              }}
              placeholder="empty"
            />
          </Link>
        </li>
      ))}
      {extraColorsLength() ? (
        <li>
          <Link
            href={`${product?.url_key as string}.html#${product?.uid}`}
            title={product?.url_key as string}
            className="text-[14px]"
          >
            + {extraColorsLength()}
          </Link>
        </li>
      ) : null}
    </ul>
  )
}
